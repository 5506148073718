<template>
  <div>
    <q-card v-if="profile">
      <q-table
        class="sticky-action full-width"
        title="Contacts"
        :rows="rows"
        :columns="columns"
        :filter="filter"
        row-key="id"
      >
        <template #top-left>
          <span class="text-h6 q-ml-sm">Contacts</span>
        </template>
        <template #top-right>
          <q-input dense debounce="300" color="primary" v-model="filter">
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </template>
        <template #body-cell-value="{ row }">
          <td>
            <a v-if="row.type === 'email'" target="_blank" :href="'mailto:' + row.value">{{ row.value }}</a>
            <a v-else-if="row.type === 'link'" target="_blank" :href="row.value">{{ row.value }}</a>
            <a v-else-if="row.type === 'phone'" target="_blank" :href="'tel:' + row.value">{{ row.value }}</a>
            <a v-else-if="row.type === 'skype'" target="_blank" :href="'skype:' + row.value + '?chat'">{{ row.value }}</a>
          </td>
        </template>
      </q-table>
    </q-card>
  </div>
</template>

<script>
import { computed, ref } from '@vue/runtime-core'
import { capitalize } from '../utils/functions'
import { useStore } from 'vuex'

export default {
  setup () {
    const store = useStore()
    const profile = computed(() => store.state.profile)

    const filter = ref('')

    const columns = ref([
      { name: 'name', label: 'Name', align: 'left', field: 'name', sortable: true },
      { name: 'type', label: 'Type', align: 'left', field: 'type', sortable: true, format: val => capitalize(val) },
      { name: 'value', label: 'Value', align: 'left', field: 'value', sortable: true }
    ])

    const rows = computed(() => store.state.contacts)

    return {
      profile,
      filter,
      columns,
      rows
    }
  }
}
</script>
